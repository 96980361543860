import React from "react"
import SEO from "../utils/seo"
import styled from "styled-components"
import { Link, graphql} from "gatsby"

const Wrapper = styled.div`
    ${props => props.theme.wrappersContainers.primaryWrapper};
    ${props => props.theme.wrappersContainers.primaryContainer};
`

const Columns = styled.div`

    display: grid;
    grid-gap:1rem;
    grid-template-columns: auto;
    grid-template-rows: repeat(4, auto);

    @media ${(props) => props.theme.mq.sm} {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, auto);
    }

    @media ${(props) => props.theme.mq.lg} {
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: auto;
    }

`

const Sitemap = ({ data }) => {

    return (

        <>
            <SEO title="Sitemap" />

            <Wrapper>

                <h1>Sitemap</h1>

                <Columns>

                    <div>
                        <h3>Pages</h3>
                        <ul>
                            {data.allWpPage.nodes.length &&  data.allWpPage.nodes.map((item, i) =>
                                <li><Link key={i} to={item.uri} title={item.title}>
                                    {item.title}
                                </Link></li>
                            )}
                        </ul>
                    </div>

                    <div>
                        <h3>Posts</h3>
                        <ul>
                            {data.allWpPost.nodes.length &&  data.allWpPost.nodes.map((item, i) =>
                                <li><Link key={i} to={item.uri} title={item.title}>
                                    {item.title}
                                </Link></li>
                            )}
                        </ul>
                    </div>

                    <div>
                        <h3>Landing Pages</h3>
                        <ul>
                            {data.allWpLandingPage.nodes.length &&  data.allWpLandingPage.nodes.map((item, i) =>
                                <li><Link key={i} to={item.uri} title={item.title}>
                                    {item.title}
                                </Link></li>
                            )}
                        </ul>
                    </div>

                    <div>
                        <h3>Properties</h3>
                        <ul>
                            {data.allWpProperty.nodes.length &&  data.allWpProperty.nodes.map((item, i) =>
                                <li><Link key={i} to={item.uri} title={item.title}>
                                    {item.title}
                                </Link></li>
                            )}
                        </ul>
                    </div>

                </Columns>

            </Wrapper>
        </>

    )

}

export default Sitemap;


export const pageQuery = graphql`
  query{
    allWpProperty {
        nodes {
            title
            uri
        }
    }
    allWpPost {
        nodes {
            title
            uri
        }
    }
    allWpPage {
        nodes {
            title
            uri
        }
    }
    allWpLandingPage {
        nodes {
            title
            uri
        }
    }
  }
`;
